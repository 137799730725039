
import { defineComponent, ref, onMounted } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { MenuComponent } from "@/assets/ts/components";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

//import ORDERS from "@/core/data/orders";
import { Order } from "@/core/model/Orders";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { ListAttributes } from "@/core/model/ListAttriibutes";
import moment from "moment";

export default defineComponent({
  name: "order-list",
  components: {
    Datatable,
  },
  setup() {
    const store = useStore();
    const tableHeader = ref([
      {
        name: "Fecha",
        key: "created_at",
        sortable: true,
      },
      {
        name: "Referencia",
        key: "reference",
        sortable: true,
      },
      {
        name: "Cliente",
        key: "customer_firstname",
        sortable: true,
      },
      {
          name: "Teléfono",
          key: "customer_phone",
          sortable: true,
      },
      {
          name: "Email",
          key: "customer_email",
          sortable: true,
      },
      {
          name: "Plan de pago",
          key: "payment_plan",
          sortable: true,
      },
      {
        name: "Total",
        key: "total_paid",
        sortable: true,
      },
      {
        name: "Estado",
        key: "status",
        sortable: true,
      },
      {
        name: "Acciones",
        key: "actions",
        sortable: false,
      },
    ]);

    const loading = ref(false);
    const rowsPerPage = ref(10);
    const currentPage = ref(1);
    const total = ref(0);
    const tableData = ref<Array<Order>>([]);
    let sortOrder = "asc";
    let sortField = "name";
    let currentSortColumn = ref("created_atasc");
    const search = ref<string>("");
    let timerSearch: number | null = null;

    onMounted(() => {
      MenuComponent.reinitialization();
      setCurrentPageBreadcrumbs("Solicitudes de crédito", ["Órdenes"]);
      loadData();
    });

    const loadData = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        search: search.value,
      } as ListAttributes;
      loading.value = true;
      store.dispatch(Actions.ORDER_LIST, params).then(() => {
        store.state.OrdersModule.list.items.forEach((item) => {
          item.created_at = formatDateTime(item.created_at);
        });

        tableData.value.splice(
          0,
          tableData.value.length,
          ...store.state.OrdersModule.list.items
        );
        loading.value = false;
        rowsPerPage.value = store.state.OrdersModule.list.limit;
        currentPage.value = store.state.OrdersModule.list.page;
        total.value = store.state.OrdersModule.list.total;
      });
    };

    const searchItems = () => {
        if (timerSearch) {
            clearTimeout(timerSearch);
            timerSearch = null;
        }
        timerSearch = setTimeout(() => {
            loadData();
        }, 800);
    };

    const paginationChangePage = function (page) {
      currentPage.value = page;
      loadData();
    };

    const paginationPerPageChange = function (limit) {
      rowsPerPage.value = limit;
      loadData();
    };

    const sortList = function (data) {
      sortField = data.columnName;
      sortOrder = data.order;
      currentSortColumn.value = sortField + sortOrder;
      loadData();
    };

    const formatDateTime = (value) => {
      return value === undefined
        ? ""
        : moment(value).format("DD/MM/YYYY hh:mm a");
    };

    return {
      tableData,
      tableHeader,
      search,
      searchItems,
      loading,
      rowsPerPage,
      currentPage,
      total,
      paginationChangePage,
      paginationPerPageChange,
      sortList,
      currentSortColumn,
    };
  },
});
